.loading-animation {
    z-index: 10;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    margin: auto;
    background: rgba(255, 255, 255, 0);
}

.animation-brand .cube {
    display: block;
    margin: 0 auto 2rem;
    width: 22rem;
    height: 22.8rem;
    fill: rgba(63, 77, 85, 0.5);
    opacity: 0;
    animation: cube 1.5s ease-out infinite;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes cube {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}